var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "landingpage"
    }
  }, [_c('HeaderComp', {
    attrs: {
      "logo": 'hide',
      "back-title": "Shopping Cart"
    }
  }), _c('c-box', {
    attrs: {
      "padding-top": "30px",
      "padding-bottom": "80px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1200px",
      "mx": "auto"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h2",
      "font-size": "36px",
      "font-weight": "600",
      "color": "black.900",
      "margin-bottom": "40px",
      "text-align": "center",
      "line-height": "54px"
    }
  }, [_vm._v(" Submit Berhasil! ")]), _c('c-flex', {
    attrs: {
      "margin-top": "100px",
      "justify-content": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "max-w": ['173px', '641px'],
      "text-align": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/paid.png'),
      "mx": "auto",
      "margin-bottom": "30px"
    }
  }), _c('c-text', {
    attrs: {
      "margin-bottom": "20px",
      "color": "gray.900",
      "font-size": ['14px', '24px'],
      "line-height": ['21px', '36px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" Progress & Tracker kamu berhasil di-submit ")]), _c('c-button', {
    attrs: {
      "as": "router-link",
      "to": {
        name: 'client.progress-tracker'
      },
      "font-weight": "700",
      "right-icon": "arrow-forward",
      "color": "primary.400",
      "variant": "outline",
      "border-color": "primary.400",
      "border-radius": "200px",
      "py": "10px",
      "px": "30px"
    }
  }, [_vm._v(" Progress Tracker ")])], 1)], 1)], 1)], 1), _c('FooterComp')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }