<template>
  <div id="landingpage">
    <HeaderComp
      :logo="'hide'"
      back-title="Shopping Cart"
    />
    <c-box
      padding-top="30px"
      padding-bottom="80px"
    >
      <c-box
        w="100%"
        max-width="1200px"
        mx="auto"
      >
        <c-heading
          as="h2"
          font-size="36px"
          font-weight="600"
          color="black.900"
          margin-bottom="40px"
          text-align="center"
          line-height="54px"
        >
          Submit Berhasil!
        </c-heading>

        <c-flex
          margin-top="100px"
          justify-content="center"
        >
          <c-box
            w="100%"
            :max-w="['173px', '641px']"
            text-align="center"
          >
            <c-image
              :src="require('@/assets/paid.png')"
              mx="auto"
              margin-bottom="30px"
            />
            <c-text
              margin-bottom="20px"
              color="gray.900"
              :font-size="['14px', '24px']"
              :line-height="['21px', '36px']"
              font-family="Roboto"
            >
              Progress &amp; Tracker kamu berhasil di-submit
            </c-text>

            <c-button
              as="router-link"
              :to="{ name: 'client.progress-tracker' }"
              font-weight="700"
              right-icon="arrow-forward"
              color="primary.400"
              variant="outline"
              border-color="primary.400"
              border-radius="200px"
              py="10px"
              px="30px"
            >
              Progress Tracker
            </c-button>
          </c-box>
        </c-flex>
      </c-box>
    </c-box>
    <FooterComp />
  </div>
</template>

<script>
import HeaderComp from '@/components/header/index.vue'
import FooterComp from '@/components/layouts/footer/index.vue'

export default {
  name: 'SuccessSubmitProgressPage',
  components: {
    HeaderComp,
    FooterComp,
  },
}
</script>
